
import { Component, Vue } from '~/utility/pu-class-decorator'

@Component
export default class Error404 extends Vue {
  get contactUsLink() {
    return `https://www.pitchup.com/${
      this.$i18n.locale !== 'en-gb' ? this.$i18n.locale + '/' : ''
    }about/contact/`
  }

  async fetchRedirects({ route }) {
    const { lang } = route.params
    const langPrefix = lang ? `${lang}/` : ''
    const url = `/_/fallback/${langPrefix}_/redirects/?path=${route.path}`
    try {
      const data = await $fetch(url)
      if (data) {
        await this.$router.push(data)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('fetchRedirects', url, error)
    }
  }

  async mounted() {
    this.$gtm.push({ event: 'page not found' })
    await this.fetchRedirects({ route: this.$route })
  }
}
