import { getLocaleInfo } from '~/lang/locales'
import { FacetGroup } from '../../domain/facetGroups'
import { getDataFromFile } from './data'

export class FacetGroupRepositoryJson {
  async getFacetGroups(langCode: string): Promise<FacetGroup[]> {
    const fileName = getLocaleInfo(langCode).fileName
    const data = await getDataFromFile(fileName)
    return data.map((group) => ({
      name: group.name,
      slug: group.slug,
      type: group.facet_type,
      facets: group.facets.map(({ name, slug }) => ({ name, slug })),
      hideEmpty: group.hide_empty,
      showByDefault: group.show_by_default,
      order: group.group_order,
    }))
  }
}
