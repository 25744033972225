import {
  ActionTypes,
  assign,
  createMachine,
  Interpreter,
  StateSchema,
} from 'xstate'
import { DatepickerAvailabilityResultsDay } from './types'

export type DatepickerAvailabilityMachineEvents =
  | {
    type: 'FETCH'
    slug: string
    categories?: string[]
    pitchtypeId?: string
  }
  | {
    // see https://xstate.js.org/docs/guides/communication.html#invoking-promises
    type: ActionTypes.ErrorPlatform
    data: unknown
  }

export interface DatepickerAvailabilityMachineContext {
  availability: DatepickerAvailabilityResultsDay[]
  fetchingParams: {
    slug: string
    categories?: string[]
    pitchtypeId?: string
  }
}

export function returnDatepickerAvailabilityMachine() {
  return createMachine<
  DatepickerAvailabilityMachineContext,
  DatepickerAvailabilityMachineEvents
  >(
    {
      initial: 'idle',
      context: {
        availability: [],
        fetchingParams: {
          slug: '',
          categories: [],
        },
      },
      on: {
        FETCH: {
          target: 'fetching',
          actions: assign({
            fetchingParams: (ctx, data) =>
              data.slug ? data : ctx.fetchingParams,
          }),
        },
      },
      states: {
        idle: {},
        fetching: {
          invoke: {
            src: 'fetch',
            onDone: {
              target: 'availabilityLoaded',
              actions: assign({
                availability: (_, { data }) => {
                  return data
                },
              }),
            },
            onError: {
              actions: 'logError',
              target: 'error',
            },
          },
        },
        availabilityLoaded: {},
        error: {
          type: 'final',
        },
      },
    },
    {
      services: {
        fetch: () => {
          throw new Error('datepickerAvailabilityMachine fetch not implemented')
        },
      },
      actions: {
        logError: () => {
          throw new Error(
            'datepickerAvailabilityMachine logError not implemented',
          )
        },
      },
    },
  )
}

export type DatepickerAvailabilityMachineInterpreter = Interpreter<
DatepickerAvailabilityMachineContext,
StateSchema,
DatepickerAvailabilityMachineEvents
>
