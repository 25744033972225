import Vue from 'vue'
import { Component, Prop, Watch } from '~/utility/pu-class-decorator'
import type { DatepickerAvailabilityMachineInterpreter } from './machine'
import { DatepickerAvailabilityResultsDay } from './types'
import { getDatepickerAvailabilityMachine } from './inversify.config'

@Component({
  fetchOnServer: false,
})
export class DatepickerAvailabilityBaseComponent extends Vue {
  datepickerAvailabilityMachine: DatepickerAvailabilityMachineInterpreter
  datepickerAvailability: DatepickerAvailabilityResultsDay[] = []

  @Prop()
    campsiteSlug: string

  @Prop({ default: () => [] })
    selectedCategoryIds: string[]

  @Prop()
    pitchtypeId: string

  get datepickerAvailabilityCampsiteSlug() {
    return this.campsiteSlug
  }

  get datepickerAvailabilitySelectedCategoryIds() {
    return this.selectedCategoryIds
  }

  availabilityLoaded = false

  async fetch() {
    try {
      await this.initAvailability(this.$route.params.lang || 'en-gb')
    } catch (e) {
      // Error logged by state machine
    }
  }

  @Watch('pitchtypeId')
  updateAvailability() {
    if (this.datepickerAvailabilityMachine) {
      this.datepickerAvailabilityMachine.send(
        'FETCH',
        this.getDatepickerAvailabilityFetchingParams(),
      )
    }
  }

  protected getDatepickerAvailabilityFetchingParams() {
    if (!this.datepickerAvailabilityCampsiteSlug) {
      throw Error(`No campsite slug available for ${this.constructor.name}`)
    }

    const params = {
      slug: this.datepickerAvailabilityCampsiteSlug,
      categories: this.datepickerAvailabilitySelectedCategoryIds,
      pitchtypeId: '',
    }

    if (this.pitchtypeId) params.pitchtypeId = this.pitchtypeId
    return params
  }

  async initAvailability(langCode: string) {
    this.datepickerAvailabilityMachine = await this.getAvailabilityMachine(
      langCode,
    )
    return new Promise<void>((resolve, reject) => {
      this.datepickerAvailabilityMachine
        .onTransition((state) => {
          if (state.changed && state.matches('availabilityLoaded')) {
            this.availabilityLoaded = true
            this.datepickerAvailability = state.context.availability || []
            resolve()
          }

          if (state.matches('error')) {
            reject(
              'DatepickerAvailabilityBaseComponent initAvailability datepickerAvailabilityMachine error',
            )
          }
        })
        .start()
        .send('FETCH', this.getDatepickerAvailabilityFetchingParams())
    })
  }

  getAvailabilityMachine(langCode: string) {
    return getDatepickerAvailabilityMachine(langCode)
  }
}
