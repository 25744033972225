import { rootContainer } from '~/container'
import { sentryCaptureMessage } from '~/utility/sentryCapture'
import { GeoSearchResult } from './types'

export class AjaxGeoSearchService {
  async get(query: string, langCode: string): Promise<GeoSearchResult[]> {
    const url = this.getUrl(query, langCode)
    const response = await $fetch<any>(url, {
      timeout: 10000,
    })
    return this.parseResponse(response)
  }

  private getUrl(query: string, langCode: string): string {
    if (!query) {
      sentryCaptureMessage(
        'AjaxGeoSearchService cannot search empty query',
        rootContainer,
      )
    }
    return `/mapbox/lang/${langCode}/query/${query}/`
  }

  private parseResponse(body: any): GeoSearchResult[] {
    if (!body.features) return []
    return body.features.map(mapRow)
  }
}

function mapRow(feature: any): GeoSearchResult {
  const [lng, lat] = feature.geometry.coordinates
  return {
    name: feature.place_name,
    point: { lat, lng, isEmptyPoint: false },
  }
}
