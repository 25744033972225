import { interpret } from 'xstate'
import isNotAnonAxiosError from '~/utility/isNotAnonAxiosError'
import sentryCapture from '~/utility/sentryCapture'
import { DatepickerAvailabilityServiceFetch } from './DatepickerAvailabilityServiceFetch'
import { returnDatepickerAvailabilityMachine } from './machine'

export function getDatepickerAvailabilityMachine(langCode: string) {
  return interpret(
    returnDatepickerAvailabilityMachine().withConfig({
      services: {
        fetch: async ({ fetchingParams: { slug, categories, pitchtypeId } }) =>
          await new DatepickerAvailabilityServiceFetch().get(
            langCode,
            slug,
            categories,
            pitchtypeId,
          ),
      },
      actions: {
        logError: (_, event: any) => {
          if (isNotAnonAxiosError(event.data)) {
            event.data.name = `datepickerAvailabilityMachine ${event.type} ${
              event.data.name
            } - ${event.code ? event.code : ''}`
            sentryCapture(event.data)
          }
        },
      },
    }),
  )
}
