import { FacetGroupsJson } from './types'

export async function getDataFromFile(fileName: string) {
  let module: any
  try {
    module = await import(
      `../../../../json/facet-groups/${fileName}.json`
    ).then((m) => m.default || m)
  } catch {
    module = await import('../../../../json/facet-groups/en.json').then(
      (m) => m.default || m,
    )
  }
  return module as FacetGroupsJson
}
