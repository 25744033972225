
import { LocaleObject } from 'vue-i18n'
import { Component, Vue } from '~/utility/pu-class-decorator'
import { getLanguageUrl } from '~/lang/utils/redirect'
import { ensureArray } from '~/utility/array'

@Component
export default class LanguageSwitch extends Vue {
  get localesList(): LocaleObject[] {
    return this.$i18n.locales
  }

  async switchLanguage(localeCode) {
    this.$gtm.push({
      event: 'language-switch',
      toLocale: localeCode,
      fromLocale: this.$i18n.locale,
    })
    await this.$i18n.setLocale(localeCode)
  }

  urlRedirect(localeCode: string): string {
    return getLanguageUrl(this.redirectUrl, localeCode)
  }

  private get redirectUrl() {
    const queryParams = new URLSearchParams()
    const query = { ...this.$route.query, page: undefined }
    Object.entries(query).forEach(([key, value]) => {
      for (const v of ensureArray(value)) {
        if (v) {
          queryParams.append(key, v)
        }
      }
    })
    const queryParamsString = queryParams.toString()
    return queryParamsString
      ? `${this.$route.path}?${queryParamsString}`
      : this.$route.path
  }

  isLanguageSelected(locale: LocaleObject) {
    return locale.code === this.$i18n.locale
  }

  closePanel() {
    this.$emit('click')
  }
}
