import { HierarchyTree } from './domain/types'
import { HierarchyTreeForPath } from './domain/usecases/HierarchyTreeForPath'
import { AjaxHierarchyReadRepository } from './infrastructure/AjaxHierarchyReadRepository'

export async function getHierarchyTree(
  hierarchyPath: string,
  langCode: string,
) {
  const hierarchyTreeGenerator = new HierarchyTreeForPath()
  let hierarchyTree: HierarchyTree
  if (hierarchyPath === '/') {
    hierarchyTree = {
      ...(await hierarchyTreeGenerator.execute(hierarchyPath, langCode)),
      label: 'All locations',
    }
  } else {
    const hTree = await hierarchyTreeGenerator.execute(
      removeCityPath(hierarchyPath),
      langCode,
    )
    hierarchyTree = { ...hTree, label: 'All locations' }
  }
  return { hierarchyTree }
}

function removeCityPath(url: string) {
  const segments = url.split('/')
  if (segments.length === 5) {
    segments.pop()
    segments.pop()
    return segments.join('/') + '/'
  }
  return url
}

const repo = new AjaxHierarchyReadRepository()
export function getHierarchyRepository() {
  return repo
}
