import { langFallback$Fetch } from '../../../lang/utils/langfetch'
import { SocialLoginResponse } from '../types'

interface Query {
  socialToken: string
}

type AuthType = 'facebook-web' | 'google-web'
interface Token {
  name: string
  value: string
}

async function getSocialLoginData(type: AuthType, token: Token) {
  const postData = new FormData()
  postData.append(token.name, token.value)
  return await langFallback$Fetch<SocialLoginResponse>(
    'en-gb',
    `/social-jwt-login/${type}`,
    {
      method: 'POST',
      mode: 'cors',
      data: postData,
    },
  )
}

export async function submitGoogleLogin({ socialToken }: Query) {
  return await getSocialLoginData('google-web', {
    name: 'credential',
    value: socialToken,
  })
}

// tslint:disable-next-line: no-identical-functions
export async function submitFacebookLogin({ socialToken }: Query) {
  return await getSocialLoginData('facebook-web', {
    name: 'accessToken',
    value: socialToken,
  })
}
