
import { Component, Vue } from '~/utility/pu-class-decorator'
import { getUrlPrefix } from '~/lang/locales'

@Component
export default class AppHeaderFavourites extends Vue {
  getFavouritesLink() {
    return `${getUrlPrefix(this.$i18n.locale)}/favourites/`
  }
}
