import { usePinia } from '#imports'
import { useAppMenuStore } from '~/stores/useAppMenuStore'
import { Component, Vue } from '~/utility/pu-class-decorator'

@Component
export class AppMenuMixin extends Vue {
  appMenu: ReturnType<typeof useAppMenuStore> | null = null
  appMenuState: string[] = []

  get appMenuVisible() {
    return this.appMenu?.appMenuVisible
  }

  get accountMenuVisible() {
    return this.appMenu?.accountMenuVisible
  }

  showAppMenu() {
    this.appMenu?.send('SHOW_APP_MENU')
  }

  showAccountMenu() {
    this.appMenu?.showAccountMenu()
  }

  hideMenu() {
    this.appMenu?.hideMenu()
  }

  toggleAccountMenu() {
    this.appMenu?.toggleAccountMenu()
  }

  toggleAppMenu() {
    this.appMenu?.toggleAppMenu()
  }

  get appMenuStates() {
    return this.appMenu?.states
  }

  async mounted() {
    this.appMenu = useAppMenuStore(usePinia())
    this.appMenuState = this.appMenu.states
    await this.$nextTick()
    await this.appMenuMounted()
  }

  async appMenuMounted() {
    // empty hook
  }
}
