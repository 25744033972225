import { getUrlPrefix } from '~~/lang/locales'
import { datepickerAvailabilityResultsSchema } from './schemas'
import { DatepickerAvailabilityResultsDay } from './types'

export class DatepickerAvailabilityServiceFetch {
  async get(
    langCode: string,
    slug: string,
    categories: Array<string> = [],
    pitchtypeId?: string,
  ): Promise<DatepickerAvailabilityResultsDay[]> {
    const params = categories.reduce((categoryParams, id) => {
      categoryParams.append('type', String(id))
      return categoryParams
    }, new URLSearchParams())
    if (pitchtypeId) {
      params.append('pitchtype_id', String(pitchtypeId))
    }
    const urlPrefix = getUrlPrefix(langCode)
    const response = await $fetch(
      `/_/fallback${urlPrefix}/_/datepicker/${slug}?${params.toString()}`,
    )
    return datepickerAvailabilityResultsSchema.parse(
      response,
    ) as DatepickerAvailabilityResultsDay[]
  }
}
