import { logger } from '../../../utility/logger'
import { getMeInfo } from './me'

export async function initialise(fetchOptions?) {
  logger('auth.initialise', fetchOptions)
  const meInfo: any = await getMeInfo(fetchOptions)
  logger('auth.initialise.result', meInfo)
  if (meInfo.status === 'unauthorized') {
    return
  }
  return meInfo
}
