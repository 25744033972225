import { render, staticRenderFns } from "./AppHeaderFavourites.vue?vue&type=template&id=8d6e1d78&scoped=true"
import script from "./AppHeaderFavourites.vue?vue&type=script&lang=ts"
export * from "./AppHeaderFavourites.vue?vue&type=script&lang=ts"
import style0 from "./AppHeaderFavourites.vue?vue&type=style&index=0&id=8d6e1d78&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d6e1d78",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,PuLink: require('/app/apps/pu-links/pu-link/PuLink.vue').default})
