import { getDomainName, langFallback$Fetch } from '~/lang/utils/langfetch'

export async function submitEmailLogin(
  {
    email,
    password,
  }: {
    email: string
    password: string
  },
  { langCode }: { langCode: string },
) {
  try {
    const response: any = await langFallback$Fetch(
      langCode,
      `/email-jwt?domain=${getDomainName()}`,
      {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        body: JSON.stringify({ email, password }),
      },
    )

    return response.token
      ? { isManager: response.is_manager }
      : { message: response.error.__all__[0] }
  } catch (e: any) {
    if (e.response && e.response.data) {
      throw { message: e.response.data.errors.__all__[0] }
    } else {
      throw { message: e.response._data.errors.__all__[0] }
    }
  }
}
