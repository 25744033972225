import { getDomainName, langFallback$Fetch } from '~/lang/utils/langfetch'

export async function submitLogout() {
  await langFallback$Fetch(
    'en-gb',
    `/accounts/logout/?domain=${getDomainName()}`,
    {
      mode: 'cors',
      redirect: 'manual',
      credentials: 'same-origin',
    },
  )
}
