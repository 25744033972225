import { LocaleObject } from 'vue-i18n'

// navigator.language will always be the iso code for the language (eg en-GB), while nuxt-i18n
// uses the code (eg en for british english) so we need to look for both code and iso
// If we don't have a translation for that locale, it will return `undefined`
export function findLocale(
  codeOrIso: string,
  locales: LocaleObject[],
): LocaleObject | undefined {
  return locales.find(l => l.code === codeOrIso || l.iso === codeOrIso)
}
