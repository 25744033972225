
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { getCampsiteCategoriesPresenter } from '~/apps/categories/presenter-json/CampsiteCategoriesUpdaterJson'

@Component
export default class SelectedCategories extends Vue {
  @Prop()
    selectedIds: string[]

  @Prop({ default: false })
    enabledOnAllUnselected: boolean

  isSelected(id: string) {
    if (this.enabledOnAllUnselected && this.selectedIds.length === 0) {
      return true
    }
    return this.selectedIds.includes(id)
  }

  get categories() {
    const categoriesPresenter = getCampsiteCategoriesPresenter(
      this.$route.params.lang || 'en-gb',
    )
    return categoriesPresenter.list
  }
}
