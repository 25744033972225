import CATEGORIES from '../../../json/categories.json'
import { arrayByKey } from '~/utility/array'
import { CampsiteCategory } from '~/utility/types/CampsiteCategory'
import { CampsiteCategoriesPresenter } from '../CampsiteCategoriesPresenter'

type CategoryJson = (typeof CATEGORIES)[0]

export class CampsiteCategoriesUpdaterJson {
  private presenter: CampsiteCategoriesPresenter = {
    list: [],
    byIdMap: {},
    bySlugMap: {},
  }

  private language: string

  constructor(language: string) {
    this.language = language.replace('-', '_')
    this.generateData()
  }

  getPresenter() {
    return this.presenter
  }

  private generateData() {
    const list = CATEGORIES.map((category) =>
      this.map(this.language, category),
    ).sort((a, b) => {
      if (a.isByo && !b.isByo) return -1
      if (!a.isByo && b.isByo) return 1
      return a.order < b.order ? -1 : 1
    })
    this.presenter.list = list
    this.presenter.byIdMap = arrayByKey(list, 'id')
    this.presenter.bySlugMap = arrayByKey(list, 'slug')
  }

  // note this gets called with map and loses this context
  private map(language: string, category: CategoryJson): CampsiteCategory {
    return {
      id: category.id.toString(),
      rootSlug: category.root_slug,
      slug: category.slug,
      name: category[`name_${language}`] || category.name,
      pretty_name:
        category[`_pretty_name_${language}`] || category._pretty_name,
      breadcrumbName: category[`name_${language}`] || category.name,
      isByo: category.is_byo,
      order: category.order,
    }
  }
}

export function getCampsiteCategoriesPresenter(language: string) {
  return new CampsiteCategoriesUpdaterJson(language).getPresenter()
}
