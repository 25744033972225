import isNotAnonAxiosError from '~/utility/isNotAnonAxiosError'
import { buildAuthMachine, getAuthMachinePure } from './authMachine'
import { submitEmailLogin } from './services/email'
import { initialise } from './services/initialise'
import { submitLogout } from './services/logout'
import { getMeInfo } from './services/me'
import { submitFacebookLogin, submitGoogleLogin } from './services/social'

export function authMachineFactory(fetchOptions?) {
  return buildAuthMachine(
    {
      getMeInfo,
      initialise: () => initialise(fetchOptions),
      submitEmailLogin,
      submitFacebookLogin,
      submitGoogleLogin,
      submitLogout,
    },
    {
      logError: (_, event) => {
        if (!event || !event.data || !event.data.response) {
          return
        }
        const response = event.data.response
        if (
          response &&
          (response.status === 403 || response.statusCode === 403)
        ) {
          return
        }
        if (isNotAnonAxiosError(event.data)) {
          event.data.name = `authMachine ${event.type} error - ${
            event.code ? event.code : ''
          }`
          console.error(event)
        }
      },
    },
  ).start(getAuthMachinePure().initialState)
}
