import { assign, createMachine, interpret, Interpreter } from 'xstate'
import { MeInfo, SocialLoginResponse } from './types'

export type AuthEvent =
  | { type: 'LOG_IN_EMAIL'; langCode: string }
  | { type: 'LOG_IN_FACEBOOK'; token: string }
  | { type: 'LOG_IN_GOOGLE'; token: string }
  | { type: 'LOG_OUT' }
  | { type: 'LOGGED_IN_ANOTHER_TAB' }
  | { type: 'LOGGED_OUT_ANOTHER_TAB' }
  | { type: 'CHANGE_EMAIL'; value: string }
  | { type: 'CHANGE_PASSWORD'; value: string }
  | { type: 'done.invoke.initialise' }

function getAuthContext() {
  return {
    email: '',
    socialToken: '',
    password: '',
    error: [] as string[],
    isManager: false,
  }
}

export type AuthContext = ReturnType<typeof getAuthContext>
export type AuthMachineInterpreter = Interpreter<AuthContext, any, AuthEvent>

// tslint:disable no-duplicate-string
export function getAuthMachinePure() {
  return createMachine<AuthContext, AuthEvent>(
    {
      id: 'auth',
      initial: 'initialising',
      context: getAuthContext(),
      states: {
        initialising: {
          invoke: {
            src: 'initialise',
            onDone: [
              {
                cond: (_, { data }) => !!data,
                target: '#auth.loggedIn',
                actions: assign({
                  email: (_, { data }) => data.email,
                  isManager: (_, { data }) => data.isManager,
                }),
                internal: true,
              },
              { target: '#auth.loggedOut.normal', internal: true },
            ],
            onError: {
              target: '#auth.loggedOut.normal',
              actions: ['logError'],
              internal: true,
            },
          },
        },
        loggedOut: {
          on: {
            LOG_IN_EMAIL: 'loggingIn.email',
            CHANGE_EMAIL: {
              actions: assign({
                email: (_, event) => event.value,
              }),
              internal: true,
            },
            CHANGE_PASSWORD: {
              actions: assign({
                password: (_, event) => event.value,
              }),
              internal: true,
            },
            LOG_IN_FACEBOOK: 'loggingIn.facebook',
            LOG_IN_GOOGLE: 'loggingIn.google',
          },
          initial: 'verifying',
          states: {
            normal: {
              entry: assign((_) => ({
                error: [] as string[],
                email: '',
                password: '',
              })),
            },
            error: {},
            verifying: {
              invoke: {
                src: 'getMeInfo',
                onDone: [
                  {
                    cond: 'isMeInfoAuthorized',
                    target: '#auth.loggedIn',
                    actions: 'assignMeInfoEmail',
                  },
                  { target: 'normal' },
                ],
                onError: {
                  target: 'normal',
                  actions: ['logError'],
                  internal: true,
                },
              },
            },
          },
        },
        loggingIn: {
          entry: assign((_) => ({
            error: [] as string[],
          })),
          exit: assign((_) => ({
            socialToken: '',
          })),
          states: {
            email: {
              invoke: {
                src: 'submitEmailLogin',
                onDone: {
                  target: '#auth.loginSuccess',
                  actions: [
                    assign({
                      password: (_) => '',
                    }),
                    'setIsManager',
                  ],
                },
                onError: [
                  {
                    target: '#auth.loggedOut.error',
                    actions: ['setError'],
                  },
                  {
                    target: '#auth.loggedOut.error',
                    actions: ['logError'],
                  },
                ],
              },
            },
            facebook: {
              entry: assign({
                socialToken: (_, event) =>
                  event.type === 'LOG_IN_FACEBOOK' ||
                  event.type === 'LOG_IN_GOOGLE'
                    ? event.token
                    : '',
              }),
              invoke: {
                src: 'submitFacebookLogin',
                onDone: {
                  target: '#auth.loginSuccess',
                  actions: [
                    assign({
                      email: (_, { data }) => data.email,
                    }),
                    'setIsManager',
                  ],
                },
                onError: {
                  actions: ['logError'],
                  target: '#auth.loggedOut',
                },
              },
            },
            google: {
              entry: assign({
                socialToken: (_, event) =>
                  event.type === 'LOG_IN_FACEBOOK' ||
                  event.type === 'LOG_IN_GOOGLE'
                    ? event.token
                    : '',
              }),
              invoke: {
                src: 'submitGoogleLogin',
                onDone: {
                  target: '#auth.loginSuccess',
                  actions: [
                    assign({
                      email: (_, { data }) => data.email,
                    }),
                    'setIsManager',
                  ],
                },
                onError: {
                  actions: ['logError'],
                  target: '#auth.loggedOut',
                },
              },
            },
          },
        },
        loggingOut: {
          invoke: {
            src: 'submitLogout',
            onDone: {
              target: '#auth.logoutSuccess',
              actions: [
                assign({
                  email: (_) => '',
                  socialToken: (_) => '',
                  password: (_) => '',
                }),
              ],
            },
            onError: {
              target: '#auth.logoutSuccess',
              actions: ['logError'],
            },
          },
        },
        logoutSuccess: {
          // For some reason xstates `after` is not working
          invoke: {
            src: 'delay100',
            onDone: '#auth.loggedOut',
          },
        },
        loginSuccess: {
          // For some reason xstates `after` is not working
          invoke: {
            src: 'delay100',
            onDone: '#auth.loggedIn',
          },
        },
        loggedIn: {
          on: {
            LOG_OUT: 'loggingOut',
            LOGGED_OUT_ANOTHER_TAB: 'loggedOut.normal',
            CHANGE_EMAIL: {
              actions: assign({
                email: (_, { value }) => value,
              }),
              internal: true,
            },
          },
        },
      },
    },
    {
      actions: {
        assignMeInfoEmail: assign({
          email: (_, { data }: any) => data.email,
        }),
        clearEmail: assign({
          email: (_) => '',
        }),
        setError: assign({ error: (_, { data }: any) => [data.message] }),
        setUnknownError: assign({ error: (_) => ['Unknown error happened'] }),
        logError: () => {
          throw new Error('authMachine logError not implemented')
        },
        setIsManager: assign({
          isManager: (_, { data }: any) => data.isManager,
        }),
      },
      guards: {
        isMeInfoAuthorized: (_, event: any) =>
          event.data.status === 'authorized',
      },
      services: {
        delay100: async (_) =>
          await new Promise((resolve) => {
            setTimeout(resolve, 100)
          }),
        getMeInfo: () => {
          throw new Error('getMeInfo not implemented')
        },
        initialise: () => {
          throw new Error('initialise not implemented')
        },
        submitEmailLogin: () => {
          throw new Error('submitEmailLogin not implemented')
        },
        submitFacebookLogin: () => {
          throw new Error('submitFacebookLogin not implemented')
        },
        submitGoogleLogin: () => {
          throw new Error('submitGoogleLogin not implemented')
        },
        submitLogout: () => {
          throw new Error('submitLogout not implemented')
        },
      },
    },
  )
}

interface BuilderServices {
  getMeInfo: () => Promise<MeInfo>
  initialise: () => Promise<{ email: string; isManager: boolean } | undefined>
  submitEmailLogin: (
    params: {
      email: string
      password: string
    },
    event,
  ) => Promise<any>
  submitFacebookLogin: (params: {
    socialToken: string
  }) => Promise<SocialLoginResponse>
  submitGoogleLogin: (params: {
    socialToken: string
  }) => Promise<SocialLoginResponse>
  submitLogout: () => Promise<void>
}

export function buildAuthMachine(services: BuilderServices, actions) {
  if (process.server) {
    services = {
      ...services,
      getMeInfo: async () => await Promise.resolve({ status: 'unauthorized' }),
      initialise: async () =>
        await Promise.resolve({ email: '', isManager: false }),
    }
  }
  return interpret(
    getAuthMachinePure().withConfig({
      services: { ...services },
      actions: {
        ...actions,
      },
    }),
  )
}
