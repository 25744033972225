import { authMachineFactory } from './factory'

let instance: ReturnType<typeof authMachineFactory> | null = null

export function getAuthService(fetchOptions?: RequestInit) {
  if (!instance) {
    instance = authMachineFactory(fetchOptions)
  }
  return instance
}
